<template>
    <div class="page">
        <div class="page-header pd">
            <div>
                <sc-button icon="plus" @click="$router.push(`new`)" v-if="$user.isAtLeast(2)">Add {{
                        resource.name
                    }}
                </sc-button>
            </div>
            <sc-search :table="table" field="q"></sc-search>
        </div>
        <sc-table :table="table" v-if="table">

            <template #column-edit="{row}">
                <sc-table-cell :table="table" field="edit" :padded="false" :clickable="false">
                    <sc-table-button @click="edit(row)" icon="edit">
                    </sc-table-button>
                </sc-table-cell>
            </template>

            <template #column-delete="{row}">
                <sc-table-cell :table="table" field="delete" :padded="false" :clickable="false">
                    <sc-table-button-delete @destroy="destroy(row)">
                        Do you wish to delete the {{ resource.name }} "{{ row.attributes.name }}"?
                    </sc-table-button-delete>
                </sc-table-cell>
            </template>


        </sc-table>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "all",
  components: {ScSearch},

  data() {
    return {
      table: null
    }
  },

  props: {
    passed: {
      required: true,
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `List of ${this.resource.plural}`,
      url: this.resource.api,
      sortBy: 'name',
      columns: {
        name: {
          width: 300,
          target: 'attributes.name',
          sort: false,
        },
        description: {
          fill: true,
          target: 'attributes.description',
          sort: false,
        },
        edit: {width: 70, sort: false, guard: !this.$user.isAtLeast(2)},
        delete: {width: 80, sort: false, guard: !this.$user.isAtLeast(2)},
      },
      hover: false,
    });
    this.table.fetch();
  },

  methods: {
    search() {
      this.table.fetch();
    },

    destroy(row) {
      this.$talker.api.delete(`${this.resource.api}/${row.id}`)
        .then(res => {
          this.table.fetch();
          this.$notify.success(`${row.attributes.name} deleted`)
        })
        .catch((err) => {
          let m = "Something went wrong.";
          if (err.response && err.response.data && err.response.data.message) {
            m = err.response.data.message;
          } else if (err.message) {
            m = err.message;
          }
          this.$notify.error(m);
        })
    },

    edit(row) {
      this.$router.push(`${row.id}/edit`)
    }
  }
}
</script>

<style scoped lang="scss">
.btns {
    text-align: center;
    padding: 2em 0 0.5em;
}
</style>